import React from 'react';
import { Wrench } from 'lucide-react';

const WorkInProgress = () => (
    <div className="flex flex-col items-center justify-center min-h-[60vh] bg-gradient-to-br from-blue-50 to-white rounded-xl shadow-lg p-8">
        <div className="relative">
            <div className="animate-bounce mb-8">
                <div className="bg-blue-100 p-4 rounded-full">
                    <Wrench size={64} className="text-blue-500 animate-pulse transform" />
                </div>
            </div>
            <div className="absolute -top-2 -right-2">
                <span className="flex h-4 w-4">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-400 opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-4 w-4 bg-blue-500"></span>
                </span>
            </div>
        </div>
        <h2 className="text-3xl font-bold text-gray-800 mb-4 text-center">
            ¡En progreso!
        </h2>
        <p className="text-gray-600 text-center max-w-md">
            Estamos trabajando en algo especial para vos. ¡Recibiras noticias próximamente! ✨
        </p>
    </div>
);

export default WorkInProgress;