import React, { useRef, useState } from "react";

const VideoCard = ({ title, videoSrc, isLoaded, onLoad, downloadText }) => (
  <div className="bg-white rounded-xl shadow-lg overflow-hidden transform transition-transform hover:scale-[1.02]">
    <div className="p-4 bg-blue-600">
      <h3 className="text-xl font-bold text-white">
        {isLoaded ? title : "Cargando..."}
      </h3>
    </div>
    <div className="p-4">
      <div className="relative aspect-video bg-gray-100 rounded-lg overflow-hidden">
        <video
          className="w-full h-full"
          src={videoSrc}
          controls
          preload="auto"
          onLoadedData={onLoad}
        />
      </div>
      {isLoaded && (
        <a
          href={videoSrc}
          className="mt-4 block w-full py-3 bg-blue-500 text-white rounded-lg text-center font-semibold hover:bg-blue-600 transition-colors"
          download
        >
          {downloadText}
        </a>
      )}
    </div>
  </div>
);

const Timelapse = ({ videoSrc, userdata }) => {
  const [isFirstVideoLoaded, setIsFirstVideoLoaded] = useState(false);
  const [isSecondVideoLoaded, setIsSecondVideoLoaded] = useState(false);

  return (
    <div className="max-w-7xl mx-auto">
      <h2 className="text-3xl font-bold text-center mb-8 text-gray-800">
        Videos del Proyecto
      </h2>
      <div className="grid md:grid-cols-2 gap-8">
        <VideoCard
          title="Video Original"
          videoSrc={videoSrc}
          isLoaded={isFirstVideoLoaded}
          onLoad={() => setIsFirstVideoLoaded(true)}
          downloadText="Descargar Video Original"
        />
        <VideoCard
          title="Video Procesado"
          videoSrc={userdata.processedVideoUrl}
          isLoaded={isSecondVideoLoaded}
          onLoad={() => setIsSecondVideoLoaded(true)}
          downloadText="Descargar Video Procesado"
        />
      </div>
    </div>
  );
};

export default Timelapse;