import React from 'react';
import { InstagramIcon, Users, MessagesSquare, Calendar, Construction, Star, Share2 } from 'lucide-react';

const CommunityPreview = () => {
    const upcomingFeatures = [
        {
            icon: <InstagramIcon className="h-6 w-6" />,
            title: "Perfiles Destacados",
            description: "Descubre los proyectos más inspiradores de nuestra comunidad",
            status: "Proximamente",
            progress: 80
        },
        {
            icon: <Users className="h-6 w-6" />,
            title: "Red de Constructores",
            description: "Conecta con otros profesionales del sector",
            status: "En desarrollo",
            progress: 60
        },
        {
            icon: <MessagesSquare className="h-6 w-6" />,
            title: "Foro de Discusión",
            description: "Comparte experiencias y resuelve dudas con la comunidad",
            status: "Planificado",
            progress: 30
        },
        {
            icon: <Calendar className="h-6 w-6" />,
            title: "Eventos y Webinars",
            description: "Participa en eventos exclusivos para miembros",
            status: "Proximamente",
            progress: 45
        }
    ];

    return (
        <div className="min-h-screen p-6 bg-gradient-to-br from-gray-50 to-blue-50">
            {/* Header */}
            <div className="max-w-7xl mx-auto text-center mb-12">
                <h1 className="text-5xl font-bold text-gray-900 mb-4">
                    Proximamente... 🚀
                </h1>
                <h1 className="text-4xl font-bold text-gray-900 mb-4">
                    Comunidad Lisual
                </h1>
                <p className="text-xl text-gray-600 max-w-2xl mx-auto">
                    Un espacio para conectar, compartir y crecer juntos
                </p>
            </div>

            {/* Featured Preview */}
            <div className="max-w-7xl mx-auto mb-12 bg-white rounded-2xl shadow-xl overflow-hidden">
                <div className="p-8">
                    <div className="flex items-center gap-2 mb-4">
                        <Star className="h-6 w-6 text-yellow-500" />
                        <h2 className="text-2xl font-semibold text-gray-900">Proyecto Destacado</h2>
                    </div>
                    <div className="grid md:grid-cols-2 gap-8 items-center">
                        <div className="space-y-4">
                            <div className="flex items-center gap-2">
                                <InstagramIcon className="h-5 w-5 text-pink-500" />
                                <span className="text-gray-700 font-medium">@proyecto_destacado</span>
                            </div>
                            <h3 className="text-xl font-semibold text-gray-800">Nombre de tu Proyecto</h3>
                            <p className="text-gray-600">
                                Buenos Aires, Argentina
                            </p>
                            <div className="flex gap-2">
                                <Share2 className="h-5 w-5 text-blue-500" />
                                <span className="text-gray-600">Compartir proyecto</span>
                            </div>
                        </div>
                        <div className="relative h-64 bg-gray-100 rounded-lg overflow-hidden">
                            <div className="absolute inset-0 flex items-center justify-center">
                                <Construction className="h-16 w-16 text-gray-400" />
                            </div>
                            <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/50 p-4">
                                <p className="text-white text-sm">Vista previa disponible próximamente</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Features Grid */}
            <div className="max-w-7xl mx-auto grid md:grid-cols-2 gap-6">
                {upcomingFeatures.map((feature, index) => (
                    <div key={index} className="bg-white p-6 rounded-xl shadow-lg border border-gray-100">
                        <div className="flex items-center gap-4 mb-4">
                            <div className="p-3 bg-blue-50 rounded-lg text-blue-600">
                                {feature.icon}
                            </div>
                            <div>
                                <h3 className="text-lg font-semibold text-gray-900">{feature.title}</h3>
                                <span className="text-sm text-blue-600 bg-blue-50 px-2 py-1 rounded-full">
                                    {feature.status}
                                </span>
                            </div>
                        </div>
                        <p className="text-gray-600 mb-4">{feature.description}</p>
                        <div className="w-full bg-gray-200 rounded-full h-2">
                            <div
                                className="bg-blue-600 h-2 rounded-full transition-all duration-500"
                                style={{ width: `${feature.progress}%` }}
                            ></div>
                        </div>
                    </div>
                ))}
            </div>

            {/* Bottom CTA */}
            <div className="max-w-2xl mx-auto text-center mt-12">
                <p className="text-gray-600 mb-4">
                    Estamos trabajando para crear la mejor experiencia para nuestra comunidad.
                    ¡Vuelve pronto para ver las novedades!
                </p>
                {/* <button
                    className="bg-blue-600 text-white px-6 py-3 rounded-lg font-medium hover:bg-blue-700 transition-colors"
                    onClick={() => { }} // You can add notification functionality later
                >
                    Notificarme cuando esté disponible
                </button> */}
            </div>
        </div>
    );
};

export default CommunityPreview;